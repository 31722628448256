<template>
  <v-footer
    absolute
    id="dashboard-core-footer"
  >
    <v-container>
      <v-row
        align="center"
        justify="center"
        no-gutters
      >
        <v-col
          cols="12"
          md="auto"
        >
          <div class="body-2 font-weight-light pt-6 pt-md-0 text-center">
            Badan Pengelola Dana Perkebunan Kelapa Sawit &copy; 2020,<a href="https://www.bpdp.or.id">v2.RP</a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',

    data: () => ({
    }),
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    a
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
</style>
